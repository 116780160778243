import React from 'react';
import {classes as stylableClasses} from './CategoryListHeader.st.css';
import {Text, TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {ConditionalRender} from '../../ConditionalRender/ConditionalRender';
import {useCategories} from '../../../hooks/useCategories';
import {ChevronLeft} from '@wix/wix-ui-icons-common/on-stage';

export const CategoryListHeader = ({onClick, dataHook}: {onClick: Function; dataHook?: string}) => {
  const {backButtonUrl, currentCategory, listHeaderCategory} = useCategories();

  const renderHeader = (enableLinkBackToParent: boolean) => {
    if (enableLinkBackToParent && backButtonUrl) {
      return (
        <TextButton
          className={stylableClasses.textButtonRoot}
          data-hook={dataHook}
          priority={TextButtonPriority.secondary}
          prefixIcon={<ChevronLeft />}
          as={'a'}
          href={backButtonUrl}
          onClick={(event) => onClick(event, currentCategory.parentCategoryId, backButtonUrl)}>
          {listHeaderCategory.name}
        </TextButton>
      );
    }

    return (
      <Text className={stylableClasses.textRoot} data-hook={dataHook}>
        {listHeaderCategory.name}
      </Text>
    );
  };

  return (
    <>
      <ConditionalRender by={'categoryListHeaderGoesBackToParent'}>{renderHeader(true)}</ConditionalRender>
      <ConditionalRender by={'categoryListHeaderDoesNothing'}>{renderHeader(false)}</ConditionalRender>
    </>
  );
};
