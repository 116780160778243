import s from './Hero.scss';
import React from 'react';
import {Image, ImageResizeOptions, Text, TextTypography} from 'wix-ui-tpa/cssVars';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import classNames from 'classnames';
import {classes as stylableClasses, st} from './Hero.st.css';
import {useStyles} from '@wix/tpa-settings/react';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {useStylesParams} from '../../../stylesParamsContext';
import {Clamp} from '../../../common/components/Clamp/Clamp';
import {CategoryHeaderLayout, Experiments} from '../../../constants';
import {ConditionalRender} from '../ConditionalRender/ConditionalRender';
import {HeadingTags} from '@wix/wixstores-client-core/dist/es/src/types/heading-tags';

export enum HeroDataHook {
  Root = 'HeroDataHook.Root',
  HeroImage = 'HeroDataHook.HeroImage',
  CategoryName = 'HeroDataHook.CategoryName',
  Description = 'HeroDataHook.Description',
}

type HeroProps = IGalleryGlobalProps & IProvidedTranslationProps;

export const Hero: React.FunctionComponent<HeroProps> = withGlobals(
  // remove sonarjs/cognitive-complexity when merging specs.stores.HeroDescriptionReadMoreImprovements
  // eslint-disable-next-line sonarjs/cognitive-complexity
  withTranslations()((props: HeroProps) => {
    const styles = useStyles();
    const stylesParams = useStylesParams();
    const shouldShowCategoryHeaderReadMoreLink = styles.get(stylesParams.gallery_showCategoryHeaderReadMoreLink);
    const {t} = props;
    const {isSEO, shouldShowMobile, handleCategoryClampClick} = props.globals;

    const {name: categoryName, description: categoryDescription, media} = props.globals.currentCategory;
    const imageExists = media?.url;
    const layout = styles.get(stylesParams.gallery_categoryHeaderLayout);

    const renderClampedDescription = () => {
      return (
        <Clamp
          className={s.descriptionContainer}
          readMoreLessLinkClassName={classNames(
            st(stylableClasses.readMoreLinkRoot, {useMobileFont: shouldShowMobile})
          )}
          text={categoryDescription}
          enabled={shouldShowCategoryHeaderReadMoreLink}
          dataHook={HeroDataHook.Description}
          maxLines={shouldShowMobile ? 3 : 2}
          readMoreText={t('categoryHeaderReadMoreLink')}
          readLessText={t('categoryHeaderReadLessLink')}
          handleClampClick={handleCategoryClampClick}>
          {categoryDescription}
        </Clamp>
      );
    };

    const getLayoutClass = () => {
      switch (layout) {
        case CategoryHeaderLayout.TextInside:
          return s.textInside;
        case CategoryHeaderLayout.TextAbove:
        case CategoryHeaderLayout.TextBelow:
        default:
          return s.textOutside;
      }
    };

    const getCategoryName = () => {
      return categoryName === 'All Products' ? t('allProducts') : categoryName;
    };

    const renderNameAndDescription = () => {
      const htmlTag = props.globals.useExperiments.enabled(Experiments.GalleryA11yFixes)
        ? props.globals.htmlTags.categoryPageHeaderHtmlTag
        : HeadingTags.H1;

      return (
        <div className={s.nameAndDescriptionContainer}>
          <ConditionalRender by={'gallery_showCategoryHeaderName'}>
            <Text
              tagName={htmlTag}
              typography={TextTypography.smallTitle}
              className={classNames(
                s.categoryName,
                st(stylableClasses.categoryNameRoot, {useMobileFont: shouldShowMobile})
              )}
              data-hook={HeroDataHook.CategoryName}>
              {getCategoryName()}
            </Text>
          </ConditionalRender>
          {categoryDescription && (
            <ConditionalRender by={'gallery_showCategoryHeaderDescription'}>
              {renderClampedDescription()}
            </ConditionalRender>
          )}
        </div>
      );
    };

    return (
      <div
        className={classNames(s.root, s.infoVerticalAlignment, getLayoutClass(), {
          [s.imageExists]: imageExists,
        })}
        data-hook={HeroDataHook.Root}>
        {imageExists && (
          <ConditionalRender by={'gallery_showCategoryHeaderImage'} className={s.imageContainer}>
            <Image
              className={stylableClasses.imageRoot}
              data-hook={HeroDataHook.HeroImage}
              src={media.url}
              sourceWidth={media.width}
              sourceHeight={media.height}
              resize={ImageResizeOptions.cover}
              isSEOBot={isSEO}
              shouldUseLQIP={true}
              fluid
            />
          </ConditionalRender>
        )}

        <ConditionalRender
          by={categoryDescription ? 'showCategoryHeaderNameOrDescription' : 'gallery_showCategoryHeaderName'}
          className={classNames(s.categoryInfo, {[s.imageExists]: imageExists})}>
          {renderNameAndDescription()}
        </ConditionalRender>
      </div>
    );
  })
);
